import React from "react"

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

// Demo styles, see 'Styles' section below for some notes on use.
// import 'react-accessible-accordion/dist/fancy-example.css';

const AccordionBlock = ({ items }) => {
  return (
    <Accordion className="mb-12 md:mb-24">
      {items.map((item, index) => {
        return (
          <AccordionItem key={`${item.title}_${index}`} className={`mb-7`}>
            <AccordionItemHeading>
              <AccordionItemButton
                className={`accordion-button bg-theme-gray cursor-pointer py-5 px-8 font-bold text-xl md:text-2xl rounded-2xl relative pr-24 tracking-[0.016em]`}
              >
                {item.title}
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  className={`absolute right-7 top-1/2 -translate-y-1/2 transition-all`}
                >
                  <circle id="Ellipse" cx="20" cy="20" r="20" fill="#d6e2ea" />
                  <g id="arrow" transform="translate(980.778 -700.999)">
                    <path
                      id="Path_6"
                      data-name="Path 6"
                      d="M47.443,992.361a.814.814,0,0,0-.194-.556l-5.778-7.111a.912.912,0,0,0-1.278-.133.875.875,0,0,0-.1,1.244l4.6,5.667H27.761a.889.889,0,1,0,0,1.778H44.694l-4.6,5.666a.919.919,0,0,0,.12,1.3.984.984,0,0,0,1.255-.186l5.778-7.111A.75.75,0,0,0,47.443,992.361Z"
                      transform="translate(-999 -271.362)"
                      fill="#004f7c"
                    />
                  </g>
                </svg>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel
              className={`accordion-panel pt-8 pl-8 pr-16 md:pr-40`}
            >
              <p className={`text-lg leading-8 tracking-[0.008em] mb-0`}>
                {item.content}
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

export default AccordionBlock
