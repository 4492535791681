import React from "react"
import PageTitle from "../components/content/PageTitle"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Accordion from "../components/ui/Accordion"

const FAQs = () => {
  const questionGroupOne = [
    {
      title: "How do I get the app?",
      content:
        <>If you have submitted the form on <a href="https://www.supplierdinewithus.co.uk">www.supplierdinewithus.co.uk</a> website and successfully verified your account, please go ahead and download the app via this link: <a href="https://get-this-app.com/mabsupplierdinewithus/" target="_blank">https://get-this-app.com/mabsupplierdinewithus/</a>. You will need to register on the app first as this is where you'll set up your password to be able to simply log in to your account in the future.</>
    },
    {
      title: "Why can't I use my personal email address?",
      content:
        "In order to confirm that you're an employee of one of our VIP third-party suppliers to Mitchells & Butlers, we ask that you register using your company email address. Discount codes cannot be sent to personal email addresses.",
    },
    {
      title: "How do I reset my password?",
      content:
        "You can do this within the app, under 'Account'. There is also a 'forgotten password?' prompt when logging in which can be used to reset your password.",
    },
    {
      title:
        "Can you get locked out for entering the incorrect password several times?",
      content:
        "There are currently no rules about being locked out after multiple incorrect password attempts. There is also a 'forgotten password?' prompt when logging in which can be used to reset your password.",
    },
    {
      title:
        "How long will I have access to my Supplier Dine With Us discount for? ",
      content:
        "Every 6 months you will receive an email asking if you wish to remain in the Supplier Dine With Us discount scheme. You will need to click on the confirmation link to retain your discount voucher. If you do not do this, you will be removed from the Supplier Dine With Us discount scheme, and will no longer receive vouchers.",
    },
  ]

  const questionGroupTwo = [
    {
      title: "How do I sign up?",
      content:
        "Once you've downloaded the app, go to the 'Account' section in the app. Fill in the information as required including your employee email address. You will also need to upload a good quality photo of you alone (i.e., not in a group), ideally showing your head & shoulders only. Once the form is complete, click 'Sign Up' at the bottom of the page.",
    },
    {
      title: "How do I navigate the app?",
      content:
        "Navigating the app is straightforward - on the homepage there are several banners which will take you to useful pages both within and outside of the app. There are also icons along the bottom of the screen to act as shortcuts to your wallet (where you'll find your voucher), a map of local businesses and your account where you can amend your details.",
    },
  ]

  const questionGroupThree = [
    {
      title: "I can't upload a photo on the app",
      content: (
        <>
          If there is a technical issue with the app, please log it to our Guest
          Care team using the form{" "}
          <a
            href="https://surveys.reputation.com/surveys3/run?tenantKey=12543018a7d&templateKey=mabfandf&locationCode=000300&stylesheet=MAB General&locale=en_US"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{" "}
          so this can be investigated.
        </>
      ),
    },
    {
      title: "Can I change my photo on the app?",
      content: (
        <>
          You can request your photo be reset by contacting our Guest Care team{" "}
          <a
            href="https://surveys.reputation.com/surveys3/run?tenantKey=12543018a7d&templateKey=mabfandf&locationCode=000300&stylesheet=MAB General&locale=en_US"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          . When uploading your new photo, it is important that the photo you
          use is good quality and of you alone (i.e. not in a group), ideally
          showing your head & shoulders only.
        </>
      ),
    },
  ]

  const questionGroupFour = [
    {
      title: "Who gets the Supplier Dine With Us discount? ",
      content: (
        <>
          <p>
            Dine With Us discount vouchers are an exclusive Company benefit for
            Mitchells & Butlers&#39; employees, friends and family and employees
            of approved third-party suppliers.{" "}
          </p>
          <p>
            Each user group has access to a bespoke app so they can enjoy
            drinking & dining with us at a discount.{" "}
          </p>
        </>
      ),
    },
    {
      title: "When can I use my Supplier Dine With Us discount? ",
      content:
        "Please go into the Supplier Dine With Us app and check the Terms & Conditions of each individual voucher in your wallet. ",
    },
    {
      title:
        "Can I use my discount in conjunction with other discounts, set menus or promotions? ",
      content: (
        <>
          <p>
            Full details are in the Terms & Conditions of each voucher in your
            app wallet, but in summary… The discount applies to all core menu
            offers including bar menus, breakfast, and standard fixed price 2 or
            3 course menus if a main meal, or equivalent* per person is ordered.
            Small plates and buffets are not considered a 'main meal' and thus
            dine with us discount cannot be used on these items.{" "}
          </p>
          <p>
            The discount applies to "order at table" and "click & collect/collection", but not delivery.
          </p>
          <p>
            Supplier Dine with us is not applicable to short term
            promotional/key date set menus in Miller & Carter (e.g. January Set
            Menu, Valentines Set Menu, Festive Set Menus).{" "}
          </p>
          <p>
            Supplier Dine With Us discount cannot be used in conjunction with
            any other special offers, coupons, vouchers or any other discount
            schemes (including staff menu).{" "}
          </p>
          <p>
            Supplier Dine With Us cannot be used in conjunction with any of our
            brand apps, this includes stamp rewards or voucher/offer redemption.{" "}
          </p>
        </>
      ),
    },
  ]

  const questionGroupFive = [
    {
      title: "How do I find my voucher? ",
      content:
        "Your voucher will be in the app 'wallet'. Once you have used your voucher, a new one will be sent to your wallet straight away for use the following day. ",
    },
    {
      title: "How soon can I use my Supplier Dine With Us discount? ",
      content:
        "As soon as you receive a voucher into your app wallet after registering, you can use the discount. The voucher will usually arrive in the app on the same day as registering. ",
    },
    {
      title: "Can I print my Supplier Dine With Us voucher? ",
      content:
        "As your voucher lives in the app, and changes after each use, we do not recommend you print it. Your voucher will be available in your app 'wallet' when you wish to redeem it. If you do print your voucher, the whole voucher content (including the QR code, code, the user's profile picture) would need to be included. ",
    },
    {
      title: "What do I do if the app is unavailable/offline? ",
      content:
        "In the event that there is a technical issue with the app, we will contact you via email with a temporary voucher.",
    },
    {
      title: "The till is saying my voucher is invalid - what should I do? ",
      content:
        "In this instance it is likely your voucher will be locked for 30 minutes, have already been used or is not valid until the following day. It will also occur if your account has been suspended or you have been removed from the scheme. ",
    },
    {
      title:
        "I have redeemed my voucher and haven't received a new one - can you resend the voucher code to my app wallet? ",
      content: (
        <>
          Yes. Please log with our Guest Care team using the form{" "}
          <a
            href="https://surveys.reputation.com/surveys3/run?tenantKey=12543018a7d&templateKey=mabfandf&locationCode=000300&stylesheet=MAB General&locale=en_US"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .{" "}
        </>
      ),
    },
    {
      title:
        "What if my voucher is marked as used but discount hasn't been applied? ",
      content:
        "The voucher will lock for a period of time, and then unlock again after 30 minutes. ",
    },
    {
      title: "How often will my voucher work? ",
      content:
        "The voucher will work once. Once a voucher is used, a new one will be sent to your app and will be valid from the following day (subject to voucher T&Cs) unless you have been removed from the scheme, deleted your account or your account has been suspended. ",
    },
    {
      title:
        "Can I use my discount to purchase mains & drinks, and then again for desserts? ",
      content:
        "If dining in a business where there is no tab functionality you must order everything in one go to be able to receive the discount on your entire bill. If you order one course and drinks and use your voucher, you will not get the discount on subsequent drinks/food you order. ",
    },
  ]

  return (
    <Layout>
      <Seo title="FAQs - Mitchells & Butlers" description="" />

      <PageTitle title="Frequently Asked Questions" />

      <div className={`container`}>
        <div className={`w-full mx-auto md:w-3/4 lg:w-2/3 pb-10 md:pb-32`}>
          <h2>Account</h2>
          <Accordion items={questionGroupOne} />

          <h2>How To</h2>
          <Accordion items={questionGroupTwo} />

          <h2>Photo</h2>
          <Accordion items={questionGroupThree} />

          <h2>T&Cs</h2>
          <Accordion items={questionGroupFour} />

          <h2>Voucher</h2>
          <Accordion items={questionGroupFive} />
        </div>
      </div>
    </Layout>
  )
}

export default FAQs
